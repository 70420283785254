
import { defineComponent } from 'vue';
import Panel from '@/components/layout/Panel.vue';
import ViewMixin from '@/mixins/ViewMixin';
import TextField from '@/components/controls/TextField.vue';
import Button from '@/components/controls/Button';
import * as guideService from '@/services/knowledge/guideService';
import { Guide } from '@/interfaces/knowledge/guide';
import LoadingIndicatorBeam from '@/components/loading/LoadingIndicatorBeam.vue';
import { redirectToDiscord } from '@/helpers/index';
import { JwtUser } from '@/interfaces/identity/user';
import DataItem from '@/components/layout/DataItem.vue';
import { ROLE_ADMINISTRATOR } from '@/constants/roles';
import * as publicUserService from '@/services/social/publicUserService';
import { PublicUser } from '@/interfaces/social/publicUser';
import PublicUserBadge from '@/components/social/user/PublicUserBadge.vue';
import { Like } from '@/interfaces/social/like';
import * as likeService from '@/services/social/likeService';

interface Data {
    searchTerm: string;
    isLoading: boolean;
    guides: Guide[];
    creators: Record<string, PublicUser>;
    likes: Like[];
}

export default defineComponent({
    name: 'Guides',
    components: {
        Button,
        Panel,
        TextField,
        LoadingIndicatorBeam,
        DataItem,
        PublicUserBadge,
    },
    mixins: [ViewMixin],
    data: (): Data => ({
        searchTerm: '',
        isLoading: false,
        guides: [],
        creators: {},
        likes: [],
    }),
    computed: {
        user(): JwtUser | null {
            return this.$store.getters['authentication/user'];
        },
        isAdmin(): boolean {
            return this.$store.getters['authentication/hasOneRoles']([ROLE_ADMINISTRATOR]);
        },
        guideLikes(): Record<string, number> {
            const guideLikes: Record<string, number> = {};
            this.guides.forEach(({ id }) => {
                guideLikes[id] = this.likes.filter(({ guideId }) => guideId === id).length;
            });

            return guideLikes;
        },
    },
    methods: {
        redirectToDiscord,
        gotoCreate(): void {
            if (!this.user) {
                this.redirectToDiscord();
            } else {
                this.$router.push({ name: 'knowledge_guide_create' });
            }
        },
        gotoGuide(id: string): void {
            this.$router.push({
                name: 'knowledge_guide',
                params: {
                    guideId: id,
                },
            });
        },
        async refreshData(): Promise<void> {
            await this.loadGuides();
            await this.loadCreators();
            await this.loadLikes();
        },
        async loadGuides(): Promise<void> {
            this.isLoading = true;
            try {
                const response = await guideService.getMultiple({
                    pageSize: -1,
                    searchQuery: this.searchTerm,
                });
                this.guides = response.data;
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
        async loadCreators(): Promise<void> {
            const creatorIds = [...new Set(this.guides.map(({ creatorId }) => creatorId))];
            if (!creatorIds.length) {
                return;
            }
            this.isLoading = true;
            try {
                const response = await publicUserService.getMultipleByIds(creatorIds);
                this.creators = {};
                response.data.forEach((creator) => {
                    this.creators[creator.id] = creator;
                });
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
        async loadLikes(): Promise<void> {
            const guideIds = [...new Set(this.guides.map(({ id }) => id))];
            if (!guideIds.length) {
                return;
            }
            this.isLoading = true;
            try {
                const response = await likeService.getMultiple({
                    guideIds: guideIds.join(','),
                    pageSize: -1,
                });
                this.likes = response.data;
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
    },
    created(): void {
        this.setPageTitle([this.$t('guides'), this.$t('knowledge')]);
        this.refreshData();
    },
});
